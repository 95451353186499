<template>
  <div class="RunWorkflow mt-5">
    <v-row
      dense
      align="center">
      <v-col
        cols="auto"
        class="mr-auto">
        <h3>Select Workflow to trigger</h3>
      </v-col>
      <v-col cols="auto">
        <v-autocomplete
            class="mb-3"
            outlined
            dense
            :value="settings.workflow"
            @input="(val) => this.setWorkflow(val)"
            @click:append-outer="listWorkflows"
            item-text="name"
            item-value="id"
            :items="workflowsList"
            label="Workflow"
            :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
            clearable></v-autocomplete>
      </v-col>
    </v-row>
    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Input Fields</p>
      </v-col>
    </v-row>
    <div
        class="RunWorkflow__param-row mb-6"
        v-for="(param, i) in settings.params"
        :key="i">
      <v-text-field
          class="RunWorkflow__param-row--field mr-2"
          outlined
          disabled
          dense
          label="Name"
          :value="param.name"
          @input="(val) => onUpdate(val, `settings.params[${i}].name`)" />
      <v-combobox
          class="RunWorkflow__param-row--field mr-2"
          outlined
          dense
          :value="param.value"
          @input="(val) => onUpdate(val, `settings.params[${i}].value`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Value"
          clearable></v-combobox>
    </div>
    <v-btn
        color="primary"
        :loading="testingScript"
        @click="showDialog = true">
      Test Workflow
    </v-btn>
    <v-dialog v-model="showDialog" width="700">
      <v-card>
        <v-card-title class="InputSettings__modal-title">
          JavaScript code editor
        </v-card-title>

        <v-card-text class="InputSettings__modal-content">
          <CodeEditor v-if="showDialog" :value="newInputScript" @input="(val) => onUpdateScript(val)" language="javascript"
                      class="mb-3" :runScript="runTestInput" :placeholder="placeholder" :suggestions="availableInputFields" />
          <p v-if="error" class="error--text">
            {{ error }}
          </p>
          <div v-if="testScriptOutput">
            <p v-if="!isNullOrUndefined(testScriptOutput)" class="text-subtitle-1 mb-3 mt-3">Test Input:</p>
            <json-viewer
                v-if="!isNullOrUndefined(testScriptOutput) && !testingScript"
                :value="testScriptOutput"
                :expand-depth="0"
                boxed
                sort
                copyable
            ></json-viewer>
          </div>
          <p v-if="!isNullOrUndefined(this.testOutput)" class="text-subtitle-1 mb-3 mt-3">Test Result:</p>
          <json-viewer
              v-if="!isNullOrUndefined(this.testOutput) && !testingScript"
              :value="this.testOutput"
              :expand-depth="0"
              boxed
              sort
              copyable
          ></json-viewer>

        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end py-3 px-4">
          <v-btn color="primary" text @click="showDialog = false">Done</v-btn>
          <v-btn color="primary" :loading="testingScript" @click="runTestInput()">
            Run Input Script
          </v-btn>
          <v-btn v-if="newInput" color="primary" :loading="testingScript" @click="testScript()">
            Test Run Workflow Action
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import 'vue-json-viewer/style.css';
import cloneDeep from "lodash/cloneDeep";
import {getModelType} from "@/util/actionsModels";

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: WorkflowGetters, mapActions: WorkflowActions } =
    createNamespacedHelpers('workflows/list');

import JsonViewer from "vue-json-viewer";
import CodeEditor from "@/components/code-editor/code-editor.vue";

export default {
  name: 'run_workflow',
  components: {
    CodeEditor,
    JsonViewer
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      testingScript: false,
      newInput: undefined,
      testOutput: null,
      showDialog: false,
      selectedWorkflow: {},
      newInputScript: '',
      testScriptOutput: '',
      placeholder: ``,
      error: ''
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      currentWorkflow: 'WORKFLOW'
    }),
    ...WorkflowGetters({
      workflows: 'AUTOMATION_WORKFLOWS_NAMES',
      loading: 'FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    workflowsList() {
      return [...this.workflows, this.settings.workflow]
    }
  },
  methods: {
    ...WorkflowActions({
      fetchWorkflows: 'fetchWorkflowsNames'
    }),
    ...workflowDetailsActions(['executeSingleAction', 'getWorkflowTriggerIO']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    onUpdateScript(value) {
      this.newInputScript = value;
    },
    async listWorkflows() {
      await this.fetchWorkflows({ customer_id: this.currentWorkflow?.customer_id });
    },
    async setWorkflow(workflow_id) {
      const workflow = this.workflows.find((workflow) => workflow.id === workflow_id);
      if(workflow) {
        this.onUpdate(workflow, 'settings.workflow');
        await this.onWorkflowSelect(workflow_id)
      }
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.params) {
        settings.params = [];
      }

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;

      this.onUpdate(settings, 'settings');
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
          !params ||
          !params[0] ||
          (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    isNullOrUndefined(data) {
      if (data === null || data === undefined) {
        return true;
      }
      return false;
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = [];
        if (this.settings.params) {
          this.settings.params.forEach(
              (param) => {
                if(typeof param.value === 'string') {
                  params.push({ name: param.name, value: param.value })
                } else {
                  params.push({ name: param.name, value: param.value.name })
                }
              }
          );
        }

        const options = {
          data: {
            params: params,
            test_mode: true,
            workflow: this.settings.workflow,
          },
          input: this.newInput || {},
          action: this.selectedNode.action_type || 'run_workflow',
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.output = data.result || 'no output';
          this.testOutput = data.model;
          const output_type = getModelType(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    async runTestInput() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;

        const options = {
          creds: null,
          data: {
            script: this.newInputScript,
          },
          action: 'execute_javascript',
          params: [],
        };
        const data = await this.executeSingleAction(options);
        if (!data || !data.success) {
          throw Error(data && data.message);
        }
        const output = data.data ?? data.result ?? 'no output';
        this.testScriptOutput = output;
        this.newInput = output;
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    async onWorkflowSelect(id) {
      const data = await this.getWorkflowTriggerIO({ workflow_id: id })
      const settings = cloneDeep(this.settings);
      settings.params = data.input;
      this.onUpdate(settings, 'settings');
      this.onUpdate(data.output, 'output_type');
    }
  },
};
</script>

<style scoped lang="scss">
@import './run-workflow';
</style>
