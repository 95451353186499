<template>
  <div>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.delimiter"
      @input="(val) => onUpdate(val, 'settings.delimiter')"
      item-text="name"
      item-value="value"
      :items="delimiters"
      label="Delimeter"
      clearable
    />

    <v-checkbox
      label="Are there header fields in the source?"
      :input-value="settings.hasHeader"
      @change="(val) => onUpdate(val, 'settings.hasHeader')"
      class="mb-3"
    />

    <v-file-input
      class="mr-1"
      accept=".csv"
      outlined
      dense
      v-model="sampleCSV"
      label="Import sample CSV file"
      :loading="testing"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getModel } from "@/util/actionsModels";

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers("workflows/details");

export default {
  name: "csv-to-json",
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      delimiters: [
        { name: "Comma", value: "," },
        { name: "Tab", value: "\t" },
        { name: "SemiColon", value: ";" },
      ],
      types: ["String", "Number", "Boolean"],
      sampleCSV: null,
      testing: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  watch: {
    sampleCSV() {
      if (!this.sampleCSV) return;
      const reader = new FileReader();
      reader.readAsText(this.sampleCSV, "UTF-8");
      reader.onload = this.parseFile;
      reader.onerror = (evt) => {
        console.log("error", evt);
      };
    },
  },
  methods: {
    ...workflowDetailsActions(["executeSingleAction"]),
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    async parseFile(evt) {
      try {
        const csvString = evt.target.result.toString();
        this.testing = true;

        const options = {
          data: this.settings,
          action: "csv_to_json",
          input: csvString,
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          const output_type = getModel(data.model, "CsvToJson");
          this.onUpdate(output_type, "output_type");
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testing = false;
        this.sampleCSV = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
