<template>
  <v-dialog
    @click:outside="closeModal"
    :value="selectedNode"
    max-width="500px"
    scrollable
    content-class="NodeInput"
  >
    <v-card class="NodeInput__card">
      <div v-if="selectedNode" class="NodeInput__content px-4">
        <p class="NodeInput__node-type text-h6 pt-4">
          <ActionIcon :action="selectedNode" class="pt-4" />
          {{ nodeType }}
        </p>

        <v-divider class="mx-n4 mb-6 NodeInput__divider" />

        <section v-if="!hideConnectorName">
          <v-text-field
            outlined
            dense
            label="Name"
            hide-details="auto"
            :value="selectedNode.name"
            @input="(val) => updateSelectedNode(val, 'name')"
            class="mb-4"
          ></v-text-field>
        </section>

        <v-btn
          v-if="!hideDocs"
          @click="openDocs"
          dense
          color="primary"
          class="mb-5"
        >
          Docs
        </v-btn>

        <section v-if="showAuthentication">
          <v-select
            class="mb-4"
            outlined
            dense
            :value="selectedNode.settings.authenticationId"
            @input="
              (val) => updateSelectedNode(val, 'settings.authenticationId')
            "
            :items="authentications"
            item-text="name"
            item-value="id"
            label="Authentication"
            clearable
          ></v-select>
        </section>

        <div v-if="showConnectorInput">
          <h3 class="mt-4 mb-2">Input</h3>
          <v-select
            class="m-3"
            outlined
            dense
            :value="nodeInput.inputNodeId"
            @input="(val) => updateSelectedNode(val, 'input.inputNodeId')"
            @change="changeInput"
            item-text="name"
            item-value="id"
            :items="availableInputs.filter((i) => i.action_type)"
            label="Connector"
            clearable
          ></v-select>
        </div>

        <div
          v-if="nodeInput && nodeInput.inputNodeId && !hideDefaultInput"
          class="mt-3"
        >
          <component
            :is="getExtraComponent"
            :availableInputFields="availableInputFields"
            :inputModel="inputModel"
            :setInputOutput="setInputOutput"
            @update="updateSelectedNode"
          />
        </div>

        <component
          :is="getConnectorComponent"
          :availableInputFields="availableInputFields"
          :nodes="nodes"
          :availableInputs="availableInputs"
          :appOptions="appointmentOptions"
          :setInputOutput="setInputOutput"
          @update="updateSelectedNode"
        />

        <output-model :model="outputType" />
        <nested-output @update="updateSelectedNode" />
      </div>

      <v-card-actions class="action-buttons">
        <v-btn color="blue darken-1" text @click="closeModal">
          <v-icon small>mdi-check</v-icon>
          Done
        </v-btn>

        <div v-if="nodeIsModified" class="discard_button">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue darken-1"
                small
                text
                @click="resetNode"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-undo-variant</v-icon>
                Discard
              </v-btn>
            </template>
            <span>Discard unsaved changes</span>
          </v-tooltip>
        </div>

        <v-btn text color="error lighten-1" @click.stop="deleteNode">
          <v-icon small>mdi-delete</v-icon>
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import lodashSet from 'lodash/set';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import lodashGet from 'lodash/get';

import {
  findNode,
  isSelectedNodeADescendantOfLoop,
  isSelectedNodeADescendantOfConditional,
  allowedNestedActionTypes,
  isSelectedNodeIsNestedAction,
} from '@/util/action-types';
import { getAction } from '@/util/get-connectors';
import { actionDocs } from '@/util/action-docs';
import OutputModel from '@/components/output-model/output-model.vue';
import { shTypes } from '@/util/actionsModels';
import { extendInputWithAuthentication } from '@/util/extendInputWithCreds';
import ActionIcon from '@/components/action-icon/action-icon.vue';
import NestedOutput from './nested-input.vue';
// connectors

import {
  CsvSettings,
  CsvToJson,
  ExcelToJson,
  JsSettings,
  HttpSettings,
  RawBrowserSettings,
  BrowserSettings,
  BrowserWorker,
  WebdriverJS,
  BenefitsSettings,
  UpdateInsuranceRecord,
  UpdateCustomFieldSettings,
  EstimatePatientCostParams,
  DateTimeSettings,
  SqlSettings,
  PatientMessagesSettings,
  PatientFlagSettings,
  RecentRibbonsSettings,
  DelaySettings,
  SFTPSettings,
  ScheduledSettings,
  WalkinVisitsSettings,
  PatientWithBalanceSettings,
  HumanInTheLoop,
  SuiteFileUpload,
  SuiteSheetReadWrite,
  SuiteEmail,
  ObjectHelperSettings,
  PverifyBenefitsSettings,
  TwilioSMSSetting,
  ListTwilioMessages,
  TwilioEmailSetting,
  SuiteReadEmail,
  OutlookReadEmail,
  SuiteFolderCreation,
  IncomingGeneralSettings,
  ListFilterSettings,
  LoopSettings,
  PatientInsuranceForm,
  Playwright,
  PatientInvoiceSettings,
  UpdatePatientChargeSettings,
  GetPatientChargesSettings,
  PatientSettings,
  PatientResetBalances,
  DropInSettings,
  RemoteVMPythonSettings,
  AWSClaudeSettings,
  AWSTextract,
  OneDriveFileMove,
  TryCatchSettings,
  GetPatientsCharges,
  ConditionalSettings,
  UpdatePatientInsurance,
  PDFSplitFile,
  SuiteUpdateSheetCell,

  // extra connectors
  ConditionalRules,
  NotifierSettings,
  RemoteVMSettings,
  DefaultSettings,
  AWSS3UploadSettings,
  ExecuteMLModelSettings,
  RunWorkflowSettings,
  WorkflowOutputSettings,
  WorkflowInputSettings,
} from '@/components/connector-settings';

import {
  getHumanInTheLoopSettings,
  getAutomationInitialSettings,
  getCurrentTimeSettings,
  getDaysBetweenSettings,
  getNotifierSettings,
} from '@/util/defaultNodeSettings';
import LlmCodeGenerationIcdSettings from '@/components/connector-settings/llm-code-generation/llm-code-generation-icd-settings.vue';
import LlmCodeGenerationCptSettings from '@/components/connector-settings/llm-code-generation/llm-code-generation-cpt-settings.vue';

const {
  mapGetters: workflowsGetters,
  mapActions: workflowsActions,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: credentialsGetters } =
  createNamespacedHelpers('credentials');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'NodeInput',
  components: {
    OutputModel,
    ActionIcon,
    NestedOutput,
  },
  data() {
    return {
      copyOfSelectedNode: null,
      authenticatedActions: [
        'browser_auto',
        'raw_browser_auto',
        'short_browser_auto_playwright',
        'long_browser_auto_playwright',
        'execute_raw_query',
        'aws_s3_upload',
        'execute_http_request',
        'sftp_upload',
        'sftp_download',
        'google_drive_upload_file',
        'google_sheets_read_data',
        'google_sheets_write_data',
        'msgraph_onedrive_upload_file',
        'msgraph_onedrive_download_file',
        'google_drive_download_file',
        'msgraph_workbook_read_data',
        'msgraph_workbook_write_data',
        'google_gmail_send_email',
        'msgraph_mail_send_email',
        'get_benefits_pverify',
        'eligibiltiy_pdf_report_pverify',
        'get_inquiry_pverify',
        'browser_auto_webdriver_js',
        'twilio_send_sms',
        'twilio_list_sms',
        'twilio_send_email',
        'google_gmail_read_email',
        'msgraph_mail_read_email',
        'msgraph_onedrive_create_folder',
        'msgraph_onedrive_get_folder',
        'msgraph_onedrive_move_file',
        'task_update_patient_insurance',
        'msgraph_workbook_update_cell',
      ],
    };
  },
  watch: {
    selectedNode: {
      handler(newNode, oldNode) {
        const oldInput = oldNode.input;
        const newInput = newNode.input;

        if (this.nodeInput && !isEqual(newInput, oldInput)) {
          this.checkIfInputIsValid();
        }
        const updatedNode = cloneDeep(newNode);
        if (this.action === 'loop_output') {
          updatedNode.action_type = 'loop';
        }
        if (this.action === 'conditional_output') {
          updatedNode.action_type = 'conditional';
        }
        this.debouncedSelectedNodeUpdate(updatedNode);
      },
      deep: true,
    },
  },
  created() {
    this.debouncedSelectedNodeUpdate = debounce(this.updateNodes, 500);
  },
  mounted() {
    const selectedNode = cloneDeep(this.selectedNode);
    this.copyOfSelectedNode = findNode(this.copyOfSavedNodes, selectedNode.id);

    if (
      !selectedNode.output_type &&
      this.actionsOutputs[selectedNode.action_type]
    ) {
      const output_type = this.actionsOutputs[this.action];
      this.updateSelectedNode(output_type, 'output_type');
    }
  },
  computed: {
    ...workflowsGetters({
      selectedNode: 'SELECTED_NODE',
      actionsOutputs: 'ACTION_OUTPUTS',
      nodes: 'NODES',
      copyOfSavedNodes: 'COPY_OF_SAVED_NODES',
      appointmentOptions: 'APPOINTMENT_OPTIONS',
      isServerless: 'IS_SERVERLESS',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    defaultNodeSettings() {
      const { action_type } = this.selectedNode;
      const { data_source_name } = this.customer.CustomersFeature;
      return getAction(action_type, data_source_name);
    },
    nodeIsModified() {
      return !isEqual(this.selectedNode, this.copyOfSelectedNode);
    },
    nodeInput() {
      return lodashGet(this.selectedNode, 'input');
    },
    action() {
      return this.selectedNode.action_type;
    },
    subject() {
      return this.selectedNode.subject;
    },
    showConnectorInput() {
      if (!this.nodeInput) return false;
      const actionsWithoutInputs = [
        'get_scheduled',
        'get_walkin_visits',
        'get_patients_with_balances',
        'human_in_the_loop',
        'object_helper',
        'google_sheets_read_data',
        'workflow_input',
        'incoming_general',
        'incoming_twilio',
        'incoming_jot_form',
        'incoming_insurance_task',
        'loop_output',
        'conditional_output',
      ];

      return !actionsWithoutInputs.includes(this.action) && this.action;
    },
    hideConnectorName() {
      return ['loop_output', 'conditional_output'].includes(this.action);
    },
    hideDefaultInput() {
      const actionWithHiddenDefaultInput = [
        'to_csv',
        'twilio_send_email',
        'format_date_time',
        'current_date_time',
        'list_filter',
        'google_gmail_send_email',
        'msgraph_workbook_read_data',
        'msgraph_workbook_write_data',
        'execute_javascript',
        'workflow_output',
        'js_pdf_fill',
        'loop_output',
        'conditional_output',
        'patient_insurance_form',
        'create_patient_invoice',
        'create_patient_data',
        'patient_create_invoice',
        'patient_create_data',
        'patient_reset_balances',
        'long_browser_auto_playwright',
        'short_browser_auto_playwright',
        'patient_update_charge',
        'patient_get_charges',
        'vm_bot_execute_python_code',
        'try_catch',
        'aws_claude_prompt',
        'aws_textract',
        'gen_ai_prompt',
        'ai_text_extract',
        'aws_s3_upload',
        'patient_get_patients_charges',
        'task_update_patient_insurance',
      ];
      if (this.isServerless) {
        actionWithHiddenDefaultInput.push(
          'google_drive_download_file',
          'msgraph_onedrive_upload_file',
          'msgraph_onedrive_download_file',
          'msgraph_onedrive_create_folder',
          'msgraph_onedrive_get_folder',
          'msgraph_onedrive_move_file',
          'aws_claude_prompt',
          'gen_ai_prompt',
          'pdf_split_file',
          'msgraph_workbook_update_cell',
          'run_workflow',
            'workflow_input',
        );
      }

      return actionWithHiddenDefaultInput.includes(this.action);
    },
    hideDocs() {
      if (actionDocs[this.selectedNode.action_type]) {
        return false;
      }
      return true;
    },
    getConnectorComponent() {
      switch (this.action) {
        case 'to_csv':
          return CsvSettings;
        case 'csv_to_json':
          return CsvToJson;
        case 'xlsx_to_json':
          return ExcelToJson;
        case 'execute_javascript':
        case 'js_pdf_fill':
          return JsSettings;
        case 'run_workflow':
          return RunWorkflowSettings;
        case 'execute_http_request':
          return HttpSettings;
        case 'browser_auto':
          return BrowserSettings;
        case 'raw_browser_auto':
          return RawBrowserSettings;
        case 'browser_worker':
          return BrowserWorker;
        case 'browser_auto_webdriver_js':
          return WebdriverJS;
        case 'aws_execute_ml_model':
          return ExecuteMLModelSettings;
        case 'get_insurance_benefits':
        case 'update_patient_insurance':
          return BenefitsSettings;
        case 'update_insurance_record':
          return UpdateInsuranceRecord;
        case 'update_custom_field':
          return UpdateCustomFieldSettings;
        case 'estimate_patient_cost_params':
          return EstimatePatientCostParams;
        case 'format_date_time':
        case 'current_date_time':
        case 'get_days_between':
          return DateTimeSettings;
        case 'execute_raw_query':
          return SqlSettings;
        case 'get_patient_messages':
          return PatientMessagesSettings;
        case 'patient_flag':
          return PatientFlagSettings;
        case 'get_recent_ribbons':
          return RecentRibbonsSettings;
        case 'delay':
          return DelaySettings;
        case 'sftp_upload':
        case 'sftp_download':
          return SFTPSettings;
        case 'get_scheduled':
          return ScheduledSettings;
        case 'get_walkin_visits':
          return WalkinVisitsSettings;
        case 'get_patients_with_balances':
          return PatientWithBalanceSettings;
        case 'human_in_the_loop':
          return HumanInTheLoop;
        case 'google_drive_upload_file':
        case 'google_drive_download_file':
        case 'msgraph_onedrive_upload_file':
        case 'msgraph_onedrive_download_file':
          return SuiteFileUpload;
        case 'msgraph_onedrive_move_file':
          return OneDriveFileMove;
        case 'google_sheets_read_data':
        case 'google_sheets_write_data':
        case 'msgraph_workbook_read_data':
        case 'msgraph_workbook_write_data':
          return SuiteSheetReadWrite;
        case 'google_gmail_send_email':
        case 'msgraph_mail_send_email':
          return SuiteEmail;
        case 'google_gmail_read_email':
          return SuiteReadEmail;
        case 'msgraph_mail_read_email':
          return OutlookReadEmail;
        case 'msgraph_onedrive_create_folder':
          return SuiteFolderCreation;
        case 'msgraph_onedrive_get_folder':
          return SuiteFolderCreation;
        case 'object_helper':
          return ObjectHelperSettings;
        case 'get_benefits_pverify':
        case 'parse_benefits_pverify':
        case 'get_inquiry_pverify':
          return PverifyBenefitsSettings;
        case 'twilio_send_sms':
          return TwilioSMSSetting;
        case 'twilio_list_sms':
          return ListTwilioMessages;
        case 'twilio_send_email':
          return TwilioEmailSetting;
        case 'incoming_general':
          return IncomingGeneralSettings;
        case 'workflow_input':
          return WorkflowInputSettings;
        case 'workflow_output':
          return WorkflowOutputSettings;
        case 'list_filter':
          return ListFilterSettings;
        case 'loop_output':
          return LoopSettings;
        case 'conditional_output':
          return ConditionalSettings;
        case 'try_catch':
          return TryCatchSettings;
        case 'patient_insurance_form':
          return PatientInsuranceForm;
        case 'short_browser_auto_playwright':
        case 'long_browser_auto_playwright':
          return Playwright;
        case 'patient_create_invoice':
          return PatientInvoiceSettings;
        case 'patient_update_charge':
          return UpdatePatientChargeSettings;
        case 'patient_get_charges':
          return GetPatientChargesSettings;
        case 'patient_create_data':
          return PatientSettings;
        case 'patient_reset_balances':
          return PatientResetBalances;
        case 'incoming_dropin':
          return DropInSettings;
        case 'vm_bot_execute_python_code':
          return RemoteVMPythonSettings;
        case 'aws_claude_prompt':
        case 'gen_ai_prompt':
          return AWSClaudeSettings;
        case 'aws_textract':
        case 'ai_text_extract':
          return AWSTextract;
        case 'aws_s3_upload':
          return AWSS3UploadSettings;
        case 'patient_get_patients_charges':
          return GetPatientsCharges;
        case 'task_update_patient_insurance':
          return UpdatePatientInsurance;
        case 'llm_code_generation_icd':
          return LlmCodeGenerationIcdSettings;
        case 'llm_code_generation_cpt':
          return LlmCodeGenerationCptSettings;
        case 'pdf_split_file':
          return PDFSplitFile;
        case 'msgraph_workbook_update_cell':
          return SuiteUpdateSheetCell;
        default:
          return null;
      }
    },
    getExtraComponent() {
      if (this.action === 'conditional') return ConditionalRules;
      if (this.subject === 'notify') return NotifierSettings;
      if (
        this.subject === 'vm_bot' &&
        this.action !== 'vm_bot_execute_python_code'
      )
        return RemoteVMSettings;

      return DefaultSettings;
    },
    showAuthentication() {
      if (this.selectedNode.action_type === 'short_browser_auto_playwright') {
        return !this.selectedNode.settings.useWorker;
      }
      return this.authenticatedActions.includes(this.selectedNode.action_type);
    },
    inputNode() {
      if (!this.selectedNode || !this.nodeInput) return null;
      const inputNodeId = this.nodeInput.inputNodeId;
      if (!inputNodeId) return null;

      return this.findNode(inputNodeId);
    },
    inputAction() {
      const inputNode = this.inputNode;
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    inputModel() {
      const nodeId = this.selectedNode.id;
      const inputNodeId = this.selectedNode?.input?.inputNodeId;
      if (
        lodashGet(this.inputNode, 'action_type') === 'loop' &&
        !isSelectedNodeADescendantOfLoop(this.nodes, nodeId, inputNodeId)
      ) {
        return this.inputNode.output_external;
      } else if (
        lodashGet(this.inputNode, 'action_type') === 'conditional' &&
        !isSelectedNodeADescendantOfConditional(this.nodes, nodeId, inputNodeId)
      ) {
        return this.inputNode.output_external;
      } else if (
        allowedNestedActionTypes.includes(
          lodashGet(this.inputNode, 'action_type')
        ) &&
        isSelectedNodeIsNestedAction(this.inputNode, nodeId)
      ) {
        return this.inputNode.nestedSettings.output_type;
      } else if (this.inputNode && this.inputNode.output_type) {
        return this.inputNode.output_type;
      } else if (this.inputAction && this.inputAction.output_type) {
        return this.inputAction.output_type;
      } else {
        return null;
      }
    },
    availableInputs() {
      const result = [];

      const addConnectors = (node) => {
        let nodes = this.nodes;
        const parentNodeId = node.path.parentNodeId
          ? node.path.parentNodeId
          : node.path.parentNode?.id;
        const parentNode = this.findNode(parentNodeId);
        const branch = node.path.branch;
        if (parentNode) {
          const parentType = parentNode.action_type;
          if (parentType === 'conditional') {
            nodes = parentNode.settings[branch];
          } else if (parentType === 'loop') {
            nodes = parentNode.settings.cycleBody;
          } else if (allowedNestedActionTypes.includes(parentType)) {
            nodes = parentNode.settings.nestedAction || [];
          } else if (parentType === 'try_catch') {
            nodes = parentNode.settings.tryCatchBody;
          }
          addConnectors(parentNode);
        }

        if (nodes.length) {
          const index = nodes.findIndex((n) => n.id === node.id);
          const connectors = nodes
            .slice(0, index + 1)
            .filter((n) => n.id !== this.selectedNode.id);
          for (const connector of connectors) {
            if (connector.action_type === 'try_catch') {
              result.push(
                ...connector.settings.tryCatchBody.filter(
                  (n) => n.id !== this.selectedNode.id
                )
              );
            }
            if (
              connector.id !== node.id &&
              connector.action_type === 'try_catch'
            ) {
              addConnectors(connector);
              continue;
            }
            result.push(connector);
          }
        }
      };

      const getParent = (node) => {
        const parentNodeId = node.path.parentNodeId
          ? node.path?.parentNodeId
          : node.path?.parentNode?.id;
        let parentNode = null;
        if (parentNodeId) {
          parentNode = this.findNode(parentNodeId);
        }
        return parentNode;
      };
      const parentNode = getParent(this.selectedNode);

      if (
        parentNode &&
        allowedNestedActionTypes.includes(parentNode.action_type)
      ) {
        result.push(parentNode);
      } else {
        addConnectors(this.selectedNode);
      }
      return result;
    },
    //available input fields
    availableInputFields() {
      let result = [];

      if (this.inputModel) {
        if (Array.isArray(this.inputModel)) {
          const items = this.inputModel
            .filter((item) => item.type === shTypes.ARRAY)
            .map((i) => ({ name: i.name, uid: i.uid, field: i }));
          result.push(...items);
        } else if (
          typeof this.inputModel === 'object' &&
          this.inputModel.type === shTypes.ARRAY
        ) {
          const items = [
            {
              name: this.inputModel.name,
              uid: this.inputModel.uid,
              field: this.inputModel,
            },
          ];
          result.push(...items);
        }
        const flattenObjFields = (items, prefix) => {
          if (isObject(items)) {
            items.forEach((prop) => {
              const propWithPrefix = {
                name: !prefix ? prop.name : `${prefix}.${prop.name}`,
                uid: prop.uid,
                field: prop,
              };

              if (prop.type === shTypes.OBJECT) {
                result.push(propWithPrefix);
                flattenObjFields(prop.value, propWithPrefix.name);
              } else {
                result.push(propWithPrefix);
              }
            });
          }
        };

        if (this.inputModel.type === shTypes.OBJECT) {
          flattenObjFields(this.inputModel.value, this.inputModel.name);
        } else {
          const items = [
            {
              name: this.inputModel.name,
              uid: this.inputModel.uid,
              field: this.inputModel,
            },
          ];
          result.push(...items);
        }
        if (this.selectedNode.action_type === 'loop') {
          result = result.filter((i) => i.field.type === shTypes.ARRAY);
        }
      }

      if (this.selectedNode.settings.authenticationId) {
        const authenticationItem = this.authentications.find(
          (item) => item.id === this.selectedNode.settings.authenticationId
        );
        return extendInputWithAuthentication(result, authenticationItem);
      }
      return result;
    },
    selectedAction() {
      const sn = this.selectedNode;
      if (!sn || !sn.action_type || !sn.actions || !sn.actions.length)
        return null;
      return sn.actions.find((a) => sn.action_type === a.action_type);
    },
    outputType() {
      if (this.action === 'loop_output' || this.action === 'conditional_output')
        return this.selectedNode.output_external;
      if (this.selectedNode && this.selectedNode.output_type)
        return this.selectedNode.output_type;
      if (this.selectedAction && this.selectedAction.output_type)
        return this.selectedAction.output_type;
      return null;
    },
    iconColor() {
      return this.selectedNode && this.selectedNode.color
        ? this.selectedNode.color
        : '#000';
    },
    nodeType() {
      return (
        this.selectedNode.display_name ||
        (this.selectedNode.action_type || '').split('_').join(' ') // fallback for existing workflows/connectors
      );
    },
  },
  methods: {
    ...workflowsActions(['updateNodes', 'removeNode']),
    ...workflowDetailsMutations({
      setSelectedNode: 'SET_SELECTED_NODE',
      setSelectedNodeOption: 'SET_SELECTED_NODE_OPTIONS',
    }),
    updateSelectedNode(value, path) {
      const selectedNode = cloneDeep(this.selectedNode);
      const updatedNode = lodashSet(selectedNode, path, value);
      this.setSelectedNode(updatedNode);
    },
    openDocs() {
      const link = actionDocs[this.selectedNode.action_type];
      if (link) {
        window.open(link, '_blank');
      }
    },
    async closeModal() {
      const selectedNode = cloneDeep(this.selectedNode);
      if (this.action === 'loop_output') {
        selectedNode.action_type = 'loop';
      }
      if (this.action === 'conditional_output') {
        selectedNode.action_type = 'conditional';
      }

      await this.updateNodes(selectedNode);
      this.setSelectedNodeOption(null);
      this.setSelectedNode(null);
    },
    deleteNode() {
      const node = cloneDeep(this.selectedNode);
      this.removeNode(node);
    },
    resetNode() {
      let node = cloneDeep(this.copyOfSelectedNode);
      if (!this.copyOfSelectedNode) {
        const { action_type } = this.selectedNode;
        let trueBranch, falseBranch, cycleBody, nestedActions, tryCatchBody;

        if (action_type === 'conditional') {
          trueBranch = this.selectedNode.settings.true;
          falseBranch = this.selectedNode.settings.false;
        } else if (action_type === 'loop') {
          cycleBody = this.selectedNode.settings.cycleBody;
        } else if (action_type === 'try_catch') {
          tryCatchBody = this.selectedNode.settings.tryCatchBody;
        } else if (allowedNestedActionTypes.includes(action_type)) {
          nestedActions = this.selectedNode.settings.nestedAction;
        }

        node = cloneDeep(this.selectedNode);
        node.input = cloneDeep(this.defaultNodeSettings).input;
        node.path = cloneDeep(this.selectedNode.path);
        node.settings = cloneDeep(this.defaultNodeSettings).settings;

        if (action_type === 'human_in_the_loop') {
          node.settings.userInput = getHumanInTheLoopSettings();
        } else if (action_type === 'browser_auto') {
          node.settings.automation = getAutomationInitialSettings();
        } else if (action_type === 'current_date_time') {
          node.settings.timeZone = getCurrentTimeSettings();
        } else if (action_type === 'get_days_between') {
          node.settings.days_between = getDaysBetweenSettings();
        } else if (action_type === 'format_date_time') {
          node.settings.fields = [];
        } else if (action_type === 'xlsx_to_json') {
          node.settings.cutKey = '';
        } else if (action_type === 'notify') {
          node.settings.to = getNotifierSettings();
        } else if (action_type === 'conditional') {
          node.settings.true = trueBranch;
          node.settings.false = falseBranch;
        } else if (action_type === 'loop') {
          node.settings.cycleBody = cycleBody;
        } else if (action_type === 'try_catch') {
          node.settings.tryCatchBody = tryCatchBody;
        } else if (allowedNestedActionTypes.includes(node.action_type)) {
          node.settings.nestedAction = nestedActions;
        }
      }

      this.setSelectedNode(node);
      this.updateNodes(node);
    },
    findNode(id) {
      return findNode(this.nodes, id);
    },
    changeInput() {
      const { input, action_type } = cloneDeep(this.selectedNode);

      if (this.availableInputFields && this.availableInputFields.length) {
        // if input field is not editable pass the same model that is in the input
        if (action_type === 'loop') {
          this.setInputOutput(this.availableInputFields[0].uid);
        } else {
          input.inputFieldPath = this.inputModel?.name;
          input.inputFieldName = this.inputModel?.name;
          input.inputFieldId = this.inputModel?.uid;
          this.updateSelectedNode(input, 'input');
        }
      }
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedNode.input);

      const selectedOption = this.availableInputFields.find(
        (f) => f.uid === uid
      );
      if (!selectedOption) {
        return this.changeInput();
      }
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;
      this.updateSelectedNode(input, 'input');
      if (this.selectedNode.action_type == 'loop') {
        // getters output is immutable
        const output_type = field.value;
        this.updateSelectedNode(output_type, 'output_type');
      }
    },
    checkIfInputIsValid() {
      const selectedNode = cloneDeep(this.selectedNode);

      if (!selectedNode.input.inputNodeId) return;
      // check if input was not deleted
      const inputNode = this.findNode(selectedNode.input.inputNodeId);
      const selectedAction =
        inputNode &&
        inputNode.actions &&
        inputNode.actions.find((a) => a.action_type === inputNode.action_type);
      if (!inputNode) {
        const snn = cloneDeep(selectedNode);
        snn.input.inputNodeId = null;
        snn.input.inputActionType = null;
        snn.output_type = null;
      } else if (!inputNode.output_type && !selectedAction) {
        selectedNode.output_type = null;
      }
      this.updateNodes(selectedNode);
    },
  },
};
</script>

<style scoped lang="scss">
@import './node-input';
</style>
